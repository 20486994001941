<template>
  <b-row>
    <b-col cols="12">
      <h2>Horarios</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <b-col cols="1" class="my-3 create_button">
        <b-button
          variant="primary"
          class="btn-ghost"
          v-b-modal.modal-add-document-admin
        >
          Añadir
        </b-button>
      </b-col>

      <datatable :items="filterItems" :fields="fields">
        <template slot="filters">
          <b-col cols="3" class="my-3 custom_filter">
            <label for="">Filtro por día</label>
            <v-select
              v-model="filterByDate"
              :reset-on-options-change="true"
              :options="daysOfWeek"
              :reduce="(value) => value.code"
            >
            </v-select>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons">
            <span
              title="ver"
              @click="show(item.uuid)"
              :id="'_show_button_' + item.uuid"
            >
              <i class="iconly-Light-Show"></i>
            </span>
            <span
              title="editar"
              @click="edit(item.uuid)"
              :id="'_show_editar' + item.uuid"
            >
              <i class="iconly-Light-Edit mr-10"></i>
            </span>
            <span
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
          </b-row>

          <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
            Ver
          </b-tooltip>

          <b-tooltip :target="'_show_editar' + item.uuid" triggers="hover">
            Editar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>

    <!-- create -->
    <b-modal
      size="xl"
      id="modal-add-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
      @shown="opened"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input v-model="data.name" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Dias">
              <v-select
                multiple
                v-model="data.day_of_week"
                :options="daysOfWeek"
                :reduce="(value) => value.code"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <b-form-group label="Hora de inicio">
                <flat-pickr
                  v-model="data.time_start"
                  :config="config"
                  class="form-control"
                  placeholder="H:i"
                />
              </b-form-group>
              <b-form-group label="Hora de Final">
                <flat-pickr
                  v-model="data.time_end"
                  :config="config"
                  class="form-control"
                  placeholder="H:i"
                />
              </b-form-group>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="created">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar este horario?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- edit -->
    <b-modal
      size="xl"
      id="modal-edit-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Editar</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input v-model="data.name" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Dias">
              <v-select
                multiple
                v-model="data.day_of_week"
                :options="daysOfWeek"
                :reduce="(value) => value.code"
              >
              </v-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <b-form-group label="Hora de inicio">
                <flat-pickr
                  v-model="data.time_start"
                  :config="config"
                  class="form-control"
                  placeholder="H:i"
                />
              </b-form-group>
              <b-form-group label="Hora de Final">
                <flat-pickr
                  v-model="data.time_end"
                  :config="config"
                  class="form-control"
                  placeholder="H:i"
                />
              </b-form-group>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="editd"> Editar </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- show -->
    <b-modal
      size="xl"
      id="modal-show-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-show-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Detalles</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input
                v-model="data.name"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Dias">
              <b-form-input
                v-model="data.daysLabel"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Hora de inicio">
              <b-form-input
                v-model="data.time_start"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Hora de Final">
              <b-form-input
                v-model="data.time_end"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-show-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import buildings from "@/logic/functions/buildings";
import building_space_schedules from "@/logic/functions/building_space_schedules";
import { IMaskComponent, IMaskDirective } from "vue-imask";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    datatable,
    flatPickr,
    vSelect,
    "imask-input": IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      fields: [
        {
          key: "day",
          label: "Nombre",
          formatter: (value, key, item) => {
            return item.name;
          },
        },
        { key: "day", label: "Dia" },
        { key: "time_start", label: "Comienza" },
        { key: "time_end", label: "Termina" },
        { key: "actions", label: "Acciones" },
      ],
      maskDob: {
        mask: "00/00/0000 00:00",
        lazy: false,
      },
      phoneMask: {
        mask: "{+56} 000 000 000",
        lazy: false,
      },
      config: {
        dateFormat: "H:i",
        inline: true,
        allowInput: false,
        noCalendar: true,
        enableTime: true,
        time_24hr: true,
      },
      filterByDate: this.getCurrentDayOfWeek(),
      daysOfWeek: [
        { label: "Domingo", code: "0" },
        { label: "Lunes", code: "1" },
        { label: "Martes", code: "2" },
        { label: "Miércoles", code: "3" },
        { label: "Jueves", code: "4" },
        { label: "Viernes", code: "5" },
        { label: "Sábado", code: "6" },
      ],
      filterByDateVal: "",
      selected: "",
      items: [],
      filterItems: [],
      Buildins: [],
      Companies: [],
      CategoryAds: [],
      data: {},
      fileUpload: {},
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    };
  },
  watch: {
    filterByDate: function (val) {
      this.filter();
    },
    "$route.params.id": {
      handler: function (value) {
        this.getBuildingInfo();
      },
    },
  },
  methods: {
    getCurrentDayOfWeek() {
      const today = new Date();
      return today.getDay().toString();
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;

      this.building_space_schedules_list(page);
    },
    opened() {
      this.data = {};
      this.data.time_start = "12:00";
      this.data.time_end = "12:00";
    },
    show(uuid) {
      this.data = this.items.find((item) => {
        return item.uuid == uuid;
      });

      let days = this.daysOfWeek.find((item) => {
        return item.code == this.data.day;
      });

      this.data.daysLabel = days.label;

      this.$bvModal.show("modal-show-document-admin");
    },
    edit(uuid) {
      this.data = {};
      this.data = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-edit-document-admin");
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    deletes(uuid) {
      let items = this.items;
      building_space_schedules
        .delete(uuid)
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === uuid);
          if (~index) {
            items.splice(index, 1);
          }
          sweet.ToastMsg("", "success", "Eliminado");
          this.filter();
        })
        .catch((error) => {});
    },
    filter() {
      this.filterItems = this.filterByDate
        ? this.items.filter((e) => e.day == this.filterByDate)
        : this.items;
    },
    async created() {
      await building_space_schedules
        .create({
          name: this.data.name,
          day: this.data.day_of_week,
          time_start: this.data.time_start,
          time_end: this.data.time_end,
          building_space_uuid: this.$route.params.id,
        })
        .then((response) => {
          this.building_space_schedules_list();
          sweet.ToastMsg("", "success", "Registrado");
          this.$bvModal.hide("modal-add-document-admin");
        })
        .catch((error) => {});
    },
    async editd() {
      await building_space_schedules
        .update(this.data.uuid, {
          name: this.data.name,
          day: this.data.day_of_week,
          time_start: this.data.time_start,
          time_end: this.data.time_end,
          building_space_uuid: this.$route.params.id,
        })
        .then((response) => {
          this.building_space_schedules_list();
          sweet.ToastMsg("", "success", "Editado");
          this.$bvModal.hide("modal-edit-document-admin");
        })
        .catch((error) => {});
    },
    async building_space_schedules_list(page = 0, params = "", day = "") {
      params =
        params == ""
          ? {
              params: {
                building_space_uuid: this.$route.params.id,
                limit: 100,
                page: page,
              },
            }
          : params;

      if (day != "" || this.filterByDate != "") {
        params.params["days"] = day != "" ? day : this.filterByDate;
      }

      await building_space_schedules
        .list(params)
        .then((response) => {
          this.items = response.data.data;
          this.filterItems = this.items;
          this.filter();
        })
        .catch((error) => {});
    },
    async buildingsList(page = 0) {
      await buildings
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.Buildins.push({ value: value.uuid, text: value.name });
          });
        })
        .catch((error) => {});
    },
  },
  beforeMount() {},
  mounted() {
    this.building_space_schedules_list();
    // this.filter();
  },
};
</script>

<style lang="scss">
.flatpickr-wrapper {
  width: 50% !important;
}
.flatpickr-input[readonly] {
  display: none;
}
.custom_filter {
  top: -2.3em;
}
.create_button {
  float: right;
  right: 4em;
  z-index: 1;
  top: 1.9em;
}
</style>
