<template>
  <b-row>
    <b-col cols="12">
      <h2>Reservas</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable :items="items" :fields="fields">
        <template slot="filters">
          <b-col cols="3" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-document-admin
            >
              Añadir
            </b-button>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons">
            <span
              title="ver"
              @click="show(item.uuid)"
              :id="'_show_button_' + item.uuid"
            >
              <i class="iconly-Light-Show"></i>
            </span>
            <span
              title="aprobar"
              @click="aprobe(item.uuid)"
              :id="'_show_aprobar' + item.uuid"
            >
              <i class="iconly-Light-ShieldDone"></i>
            </span>
            <span
              title="editar"
              @click="edit(item.uuid)"
              :id="'_show_editar' + item.uuid"
            >
              <i class="iconly-Light-Edit mr-10"></i>
            </span>
            <span
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
          </b-row>

          <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
            Ver
          </b-tooltip>

          <b-tooltip :target="'_show_editar' + item.uuid" triggers="hover">
            Editar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>

          <b-tooltip :target="'_show_aprobar' + item.uuid" triggers="hover">
            Aprobar
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar esta reservacion?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- create -->
    <b-modal
      size="xl"
      id="modal-add-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
      @shown="opened"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Seleccione departamento">
              <b-form-select
                v-model="data.department_uuid"
                :options="departaments"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <b-form-group label="Dia">
                <flat-pickr
                  v-model="selectedDate"
                  :config="config"
                  class="form-control"
                  placeholder="Seleccione una fecha"
                  @on-change="onDateChange"
                />
              </b-form-group>
              <b-form-group
                v-if="availableSchedules.length > 0"
                label="Horarios disponibles"
              >
                <b-form-select
                  v-model="selectedSchedule"
                  :options="availableScheduleOptions"
                  @change="onScheduleChange"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="!availableSchedules.length > 0"
                label="no hay horarios disponibles"
              >
              </b-form-group>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="created">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- edit -->
    <b-modal
      size="xl"
      id="modal-edit-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Editar</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Seleccione departamento">
              <b-form-select
                v-model="documentCreateEdit.department_uuid"
                :options="departaments"
                @change="onEditDepartement"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <b-form-group label="Dia">
                <flat-pickr
                  v-model="selectedDate"
                  :config="config"
                  class="form-control"
                  placeholder="Seleccione una fecha"
                  @on-change="onDateChange"
                />
              </b-form-group>
              <b-form-group
                v-if="availableSchedules.length > 0"
                label="Horarios disponibles"
              >
                <b-form-select
                  v-model="selectedSchedule"
                  :options="availableScheduleOptions"
                  @change="onScheduleChange"
                ></b-form-select>
              </b-form-group>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="editd"> Editar </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- show -->
    <b-modal
      id="modal-show-document-admin"
      size="xl"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-show-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Detalles</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Seleccione departamento">
              <b-form-select
                v-model="documentCreateEdit.department_uuid"
                :options="departaments"
                @change="onEditDepartement"
                :disabled="true"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Fecha y hora de inicio">
              <b-form-input
                v-model="data.time_start"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Fecha y hora final">
              <b-form-input
                v-model="data.time_end"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-show-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import buildings from "@/logic/functions/buildings";
import buildings_space_logs from "@/logic/functions/buildings_space_logs";
import departaments from "@/logic/functions/departaments";
import { DateTime } from "luxon";
import { IMaskComponent, IMaskDirective } from "vue-imask";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    datatable,
    flatPickr,
    vSelect,
    "imask-input": IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      fields: [
        {
          key: "department_uuid",
          label: "Departamento",
          formatter: (value, key, item) => {
            return item.department_uuid.nro;
          },
        },
        { key: "time_start", label: "hora de inicio" },
        { key: "time_end", label: "hora final" },
        { key: "actions", label: "Acciones" },
      ],
      maskDob: {
        mask: "00/00/0000 00:00",
        lazy: false,
      },
      phoneMask: {
        mask: "{+56} 000 000 000",
        lazy: false,
      },
      config: {
        dateFormat: "d-m-Y",
        inline: true,
        allowInput: false,
        static: true,
      },
      selectedRange: "12:00 hrs - 12:00 hrs",
      selectedDate: null,
      availableSchedules: [],
      selectedSchedule: null,
      selectedScheduleObj: null,
      daysOfWeek: [
        { label: "Domingo", code: "0" },
        { label: "Lunes", code: "1" },
        { label: "Martes", code: "2" },
        { label: "Miércoles", code: "3" },
        { label: "Jueves", code: "4" },
        { label: "Viernes", code: "5" },
        { label: "Sábado", code: "6" },
      ],
      departaments: [],
      items: [],
      Buildins: [],
      Companies: [],
      CategoryAds: [],
      data: {},
      fileUpload: {},
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
      documentCreateEdit: {
        dni_type_uuid: 3,
      },
    };
  },
  methods: {
    async getAvailableSchedules(date) {
      try {
        const dateStr = this.formatDate(date);
        const response = await buildings_space_logs.getAvailableSchedules(
          this.$route.params.id,
          dateStr
        );
        this.availableSchedules = response.data;
      } catch (error) {
        console.error("Error fetching available schedules:", error);
      }
    },
    onScheduleChange(uuid) {
      if (uuid)
        this.selectedScheduleObj = this.availableSchedules.find(
          (e) => e.uuid === uuid
        );
      else this.selectedScheduleObj = null;
    },
    formatDate(dateStr) {
      const [day, month, year] = dateStr.split("-");
      return `${year}-${month}-${day}`;
    },
    formatDateEdit(dateStr) {
      const [year, day, month] = dateStr.split("-");
      return `${month}-${day}-${year}`;
    },
    onDateChange(selectedDates, dateStr, instance) {
      this.getAvailableSchedules(dateStr);
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;

      this.building_space_schedules_list(page);
    },
    opened() {
      this.data = {};
    },
    onEditDepartement(departament) {
      this.data.department_uuid = departament;
    },
    show(uuid) {
      this.data = {};
      this.data = this.items.find((item) => {
        return item.uuid == uuid;
      });
      if (
        this.data.department_uuid &&
        typeof this.data.department_uuid === "object"
      ) {
        this.documentCreateEdit.department_uuid =
          this.data.department_uuid.uuid;
      }

      let days = this.daysOfWeek.find((item) => {
        return item.code;
      });
      this.data.daysLabel = days.label;
      this.$bvModal.show("modal-show-document-admin");
    },
    edit(uuid) {
      this.data = {};
      this.data = this.items.find((item) => {
        return item.uuid == uuid;
      });
      this.selectedDate = this.formatDateEdit(this.data.date);
      this.getAvailableSchedules(this.selectedDate);
      if (
        this.data.department_uuid &&
        typeof this.data.department_uuid === "object"
      ) {
        this.documentCreateEdit.department_uuid =
          this.data.department_uuid.uuid;
      }

      this.$bvModal.show("modal-edit-document-admin");
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    deletes(uuid) {
      let items = this.items;
      buildings_space_logs
        .delete(uuid)
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === uuid);
          if (~index) {
            items.splice(index, 1);
          }
          sweet.ToastMsg("", "success", "Eliminado");
        })
        .catch((error) => {});
    },
    async aprobe(uuid) {
      await buildings_space_logs
        .aprobe(uuid)
        .then((response) => {
          sweet.ToastMsg("", "success", "Aprobado");
        })
        .catch((error) => {});
    },
    async created() {
      await buildings_space_logs
        .create({
          department_uuid: this.data.department_uuid,
          building_space_schedule_uuid: this.selectedScheduleObj.uuid,
          datetime_start: this.selectedScheduleObj.time_start,
          datetime_end: this.selectedScheduleObj.time_end,
          date: this.formatDate(this.selectedDate),
        })
        .then((response) => {
          this.building_space_schedules_list();
          sweet.ToastMsg("", "success", "Registrado");
          this.$bvModal.hide("modal-add-document-admin");
        })
        .catch((error) => {});
    },
    async editd() {
      await buildings_space_logs
        .update(this.data.uuid, {
          building_space_schedule_uuid: this.selectedScheduleObj.uuid,
          department_uuid: this.data.department_uuid.uuid,
          datetime_start: this.data.time_start,
          datetime_end: this.data.time_end,
          date: this.formatDate(this.selectedDate),
        })
        .then((response) => {
          this.building_space_schedules_list();
          sweet.ToastMsg("", "success", "Editado");
          this.$bvModal.hide("modal-edit-document-admin");
        })
        .catch((error) => {});
    },
    async building_space_schedules_list(page = 0) {
      await buildings_space_logs
        .list({
          params: {
            //'building_space_schedule_uuid' : this.$route.params.id,
            current_schedules: false,
            limit: 5,
            page: 0,
          },
        })
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {});
    },
    async departamentsList(page = 0) {
      await departaments
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.departaments.push({ value: value.uuid, text: value.nro });
          });
        })
        .catch((error) => {});
    },
  },
  computed: {
    availableScheduleOptions() {
      return this.availableSchedules.map((schedule) => ({
        value: schedule.uuid,
        text: `${schedule.name} (${schedule.time_start} - ${schedule.time_end})`,
      }));
    },
  },
  beforeMount() {},
  mounted() {
    this.building_space_schedules_list();
    this.departamentsList();
  },
};
</script>

<style lang="scss">
.flatpickr-wrapper {
  width: 50% !important;
}
.flatpickr-input[readonly] {
  display: none;
}
</style>
<style scoped>
.time-range-selector {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}
select {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
