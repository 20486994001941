//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class buildings {
  
  get(data) {
    return http.get( api.buildings.get.endpoint, data );
  }

  list() {
    return http.get( api.buildings.get.endpoint );
  }

  create(Building_data) {
    return http.post( api.buildings.create.endpoint,Building_data);
  }

  update(Building_uuid,Building_data) {
    return http.put( api.buildings.update.endpoint+Building_uuid,Building_data);
  }

  delete(Building_uuid) {
    return http.delete( api.buildings.delete.endpoint+Building_uuid );
  }

}

export default new buildings();