//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class buildingSpaceLogs {
  
  list(data) {
    return http.get( api.buildings_space_logs.get.endpoint, data );
  }

  create(data) {
    return http.post( api.buildings_space_logs.create.endpoint,data);
  }

  aprobe(uuid) {
    return http.put( api.buildings_space_logs.aprobe.endpoint+uuid);
  }

  getAvailableSchedules(buildingSpaceUuid, dateStr) {
    return http.get(`/building_space_schedules/available/${buildingSpaceUuid}/${dateStr}`);
  }

  update(uuid,data) {
    return http.put( api.buildings_space_logs.update.endpoint+uuid,data);
  }

  delete(uuid) {
    return http.delete( api.buildings_space_logs.delete.endpoint+uuid );
  }

}

export default new buildingSpaceLogs();