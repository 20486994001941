<template>
  <b-col class="hp-profile-menu py-24 px-0" style="flex: 0 0 240px">
    <div class="w-100">
      <div class="hp-profile-menu-header mt-16 mt-lg-0 text-center">
        <div class="hp-menu-header-btn mb-12 text-right">
          <!--
          <b-dropdown variant="link" no-caret toggle-class="p-0">
            <template #button-content>
              <div class="d-inline-block">
                <b-button variant="text" class="btn btn-icon-only">
                  <i
                    class="ri-more-2-line text-black-100 hp-text-color-dark-30 lh-1"
                    style="font-size: 24px"
                  ></i>
                </b-button>
              </div>
            </template>
            <b-dropdown-item href="#">Change Avatar</b-dropdown-item>
          </b-dropdown>
          -->
        </div>

        <div class="d-flex justify-content-center">
          <div class="d-inline-block position-relative">
            <div
              class=" d-flex align-items-center justify-content-center rounded-circle"
              style="width: 100px;height: 100px;background-color: #f4620a;"
            >
              <img :src="require('@/assets/img/sales/sales.png')" />
            </div>

            <!-- <b-badge
              pill
              class="position-absolute bg-primary text-white border-0"
            >
              12
            </b-badge> -->
          </div>
        </div>

        <h2 class="mt-24 mb-4"> Espacios de reserva </h2>
        <span class="hp-p1-body text_description_sidebar" v-if ="!$route.name == 'admin-communications-specific-reserva'">
          Aquí podrás ver todo lo relacionado con los espacios de reserva
        </span>
        <h3 else class="mt-14 mb-3">
          {{ buildingName }}
        </h3>
      </div>
    </div>

    <div class="hp-profile-menu-body w-100 text-left mt-48 mt-lg-0">
      <ul>
 
        <li class="mt-4 mb-16">
          <b-link
            :to=reserve
            replace
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              route_name == 'admin-communications-specific-reserva' ? ' active' : ''
            }`"
          >
          <i class="iconly-Light-TwoUsers mr-8"></i>
            <span>Horarios</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

        <li class="mt-4 mb-16">
          <b-link
            :to=aprobe
            replace
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              route_name == 'admin-communications-specific-aprobe' ? ' active' : ''
            }`"
          >
          <i class="iconly-Light-TwoUsers mr-8"></i>
            <span>Reservas</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>

 
 
      </ul>
    </div>

    <div class="hp-profile-menu-footer">
      <img
        :src="require('@/assets/img/pages/profile/menu-img.svg')"
        alt="Profile Image"
      />
    </div>
  </b-col>
</template>

<script>
import {
  BCol,
  BBadge,
  BButton,
  BLink,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import buildingSpaces from '@/logic/functions/buildingSpaces';

export default {
  data() {
      return {
        data : this.building,
        path : "",
        params : "",
        route_name : "",
        departament : "",
        information : "",
        administracion : "",
        buildingName: null,
      };
  },
  props: {
    building:{
      type: Object,
    },
  },
  components: {
    BCol,
    BBadge,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  watch: {
    $route() {
      this.getReservId();
      this.getRoute();
    },
    building(){
      this.data = this.building
    },
  },
  methods: {
    getRoute() {
      this.path = this.$route.path;
      this.params = this.$route.params;
      this.route_name = this.$route.name;

      this.reserve = '/admin/modulos/reservas/'+this.$route.params.id;
      this.aprobe = '/admin/modulos/reservas/aprobar/'+this.$route.params.id;
    },
    async getReservId() {
      await buildingSpaces.list({
          params: {
            building_space_uuid: this.$route.params.id,
          },
        })
        .then((response) => {
          this.buildingName = response.data.name;
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "no se pudo obtener los edificios");
        });
    },
  },
  async mounted() {
    await this.getReservId();
  },
  beforeMount() {
 
  },
  created() {
    this.getRoute();
  },
};
</script>

<style lang="scss">
@import '~flatpickr/dist/flatpickr.css';
.flatpickr-wrapper {
  width: 50% !important;
}
</style>