//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class buildingSpaceSchedules {
  
  get(data) {
    return http.get( api.building_space_schedules.get.endpoint, data );
  }

  list(data) {
    return http.get( api.building_space_schedules.get.endpoint, data );
  }

  create(data) {
    return http.post( api.building_space_schedules.create.endpoint,data);
  }

  update(uuid,data) {
    return http.put( api.building_space_schedules.update.endpoint+uuid,data);
  }

  delete(uuid) {
    return http.delete( api.building_space_schedules.delete.endpoint+uuid );
  }

}

export default new buildingSpaceSchedules();